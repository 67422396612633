<template>
  <SfBreadcrumbs
    class="breadcrumbs desktop-only"
    :breadcrumbs="breadcrumbs"
    :class="{'has-hero clamped affix-breadcrumbs': hasHero, 'clamped--text': !hasHero && !isWide, 'clamped': isWide && !hasHero}"
  />
</template>

<script>
import { SfBreadcrumbs } from '@storefront-ui/vue';
export default {
  name: 'CmsBreadcrumbs',
  components: {
    SfBreadcrumbs
  },
  props: {
    current: {
      type: Object,
      required: false,
      default: () => ({})
    },
    category: {
      type: Object,
      required: false,
      default: () => ({})
    },
    subCategory: {
      type: Object,
      required: false,
      default: () => ({})
    },
    hasHero: {
      type: Boolean,
      required: false,
      default: true
    },
    isWide: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    breadcrumbs() {
      if (this.$route.path === '/') {
        return;
      }

      const crumbs = [{
        text: 'Home',
        link: '/'
      }];

      if (this.category?.title) {
        crumbs.push({
          text: this.category?.title,
          link: this.$prismic.asLink(this.category),
        });
      }

      if (this.subCategory?.data?.title) {
        crumbs.push({
          text: this.subCategory?.data?.title,
          link: this.$prismic.asLink(this.subCategory),
        });
      }

      crumbs.push({
        text: String(this.current?.data?.title),
        link: this.$prismic.asLink(this.current),
      });

      return crumbs;
    }
  }
};
</script>

<style scoped lang="scss">
.breadcrumbs {
  font-size: 0.85em;
  padding-block: 0.5em;
  --breadcrumb-color: var(--c-link);
  --breadcrumbs-list-item-before-color: var(--breadcrumb-color);
}

.affix-breadcrumbs {
  position: absolute;
  padding-inline: 0.75em;
  top: 0.25em;
  left: 0;
  right: 0;
  z-index: 25;
  color: $white;
  opacity: 0.5;
  transition: 150ms opacity 300ms;
}

.has-hero.breadcrumbs {
  --breadcrumb-color: #fff;
}

.breadcrumbs:hover {
  opacity: 1;
}

.sf-breadcrumbs__breadcrumb,
.sf-breadcrumbs__breadcrumb:hover,
.sf-breadcrumbs__breadcrumb.current {
  color: var(--breadcrumb-color);
}
</style>
